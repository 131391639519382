import { createContext } from 'react'

import { Child, User } from '@/graphql/models'

export type UserContextType = {
  user: Pick<User, 'id' | 'name' | 'email' | 'status' | 'childList'> | null | undefined
  selectedChild:
    | Pick<Child, 'id' | 'name' | 'gender' | 'birthday' | 'individualLessonType' | 'status'>
    | null
    | undefined
}

const defaultValue: UserContextType = {
  user: null,
  selectedChild: null,
}

export const userContext = createContext(defaultValue)
