import { Box, Container } from '@mui/material'
import React from 'react'

import { Footer } from '@/layouts/parts/MainLayout/Footer'
import { Header } from '@/layouts/parts/MainLayout/Header'

export const MainLayout: React.FC<React.ReactNode> = ({ children }) => {
  const [appHeight, setAppHeight] = React.useState(window.innerHeight - 56)

  window.addEventListener('load', () => {
    setAppHeight(window.innerHeight - 56)
  })

  window.addEventListener('resize', () => {
    setAppHeight(window.innerHeight - 56)
  })

  return (
    <Box sx={{ backgroundColor: '#EBFFCE' }}>
      <Header />
      <Box sx={{ py: 2, height: `${appHeight}px` }}>
        <Container sx={{ px: 1, height: '100%', position: 'relative' }}>{children}</Container>
      </Box>
      <Footer />
    </Box>
  )
}
