import { Button } from '@mui/material'
import React from 'react'

import { SelectChildModal } from '@/components/pages/child/SelectChildModal'
import { userContext } from '@/context/user'

export const Header = () => {
  const { user, selectedChild } = React.useContext(userContext)
  const [modalOpen, setModalOpen] = React.useState(false)

  const isOnlyChild = () => {
    return user?.childList.length === 1
  }

  const handleOpen = () => (isOnlyChild() ? setModalOpen(false) : setModalOpen(true))
  const handleClose = () => setModalOpen(false)

  return (
    <>
      <Button
        onClick={handleOpen}
        color="primary"
        variant="contained"
        size="small"
        sx={{
          minWidth: '5rem',
          position: 'fixed',
          top: 19,
          right: 24,
          zIndex: 100,
          fontSize: '0.7rem',
          backgroundColor: '#6DC344',
          fontWeight: 'bold',
        }}
      >
        {selectedChild?.name}
      </Button>
      <SelectChildModal isOpen={modalOpen} handleClose={handleClose} />
    </>
  )
}
