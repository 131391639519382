import { ServerError } from '@apollo/client'
import Router, { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

import { Loading } from '@/components/common/Loading'
import { UserContextType, userContext } from '@/context/user'
import { Child } from '@/graphql/models'
import { useFindMeQuery } from '@/graphql/page/index'

type Props = {
  requireAuth: boolean
}

export const AuthProvider: FC<Props> = ({ children, requireAuth }) => {
  const { data, loading, error, refetch } = useFindMeQuery()
  const router = useRouter()
  const [cookies, setCookie] = useCookies(['childId'])

  const [ctx, seCtx] = useState<UserContextType | null>(null)

  useEffect(() => {
    if (loading === false) {
      const networkError = error?.networkError as ServerError | null
      if (networkError) {
        switch (networkError.statusCode) {
          case 401:
          case 419:
            if (requireAuth) {
              Router.replace('/login')
            }
            break
          case 403:
            Router.replace('/needEmailVerified')
            break
          default:
            Router.replace('/login')
        }

        return
      }

      const isLoggedIn = data?.me !== undefined

      if (requireAuth && !isLoggedIn) {
        Router.replace('/login')
      }

      if (!requireAuth && isLoggedIn) {
        Router.replace('/')
      }
    }

    const selectedChild = (() => {
      if (cookies.childId) {
        return data?.me?.childList.find((child: Child) => String(child.id) === cookies.childId)
      }

      const child = data?.me?.childList[0]
      if (child) {
        setCookie('childId', child.id, { path: '/' })
      }

      return child
    })()

    switch (selectedChild?.status) {
      case 'pending':
        if (router.pathname !== '/selectPlan' && router.pathname !== '/selectPlan/callback') {
          Router.replace('/selectPlan')
        }
        break
      case 'plan_selected':
        Router.replace('/sending')
        break
      case 'text_sent':
        Router.replace('/reservation')
        break
      case 'active':
        Router.replace('/')
        break
    }

    const ctx: UserContextType = {
      user: data?.me,
      selectedChild: selectedChild,
    }

    seCtx(ctx)
  }, [loading, data, error])

  useEffect(() => {
    refetch()
  }, [router.pathname, cookies.childId])

  if (loading || !ctx || (requireAuth && (!ctx.user || !ctx.selectedChild))) {
    return <Loading />
  }

  return <userContext.Provider value={ctx}>{children}</userContext.Provider>
}
