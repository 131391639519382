import { Close } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton, Modal as ModalBase } from '@mui/material'
import React from 'react'

export const Modal: React.FC<{
  open: boolean
  title: string
  handleClose: () => void
}> = ({ open, title, handleClose, children }) => {
  const [appHeight, setAppHeight] = React.useState(window.innerHeight * 0.9)

  window.addEventListener('load', () => {
    setAppHeight(window.innerHeight * 0.9)
  })

  window.addEventListener('resize', () => {
    setAppHeight(window.innerHeight * 0.9)
  })

  return (
    <ModalBase
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          height: `${appHeight}px`,
        }}
      >
        <CardHeader
          title={title}
          action={
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          }
          sx={{
            borderBottom: 4,
            borderBottomColor: 'primary.main',
          }}
        />
        <CardContent className={'scrollableY'} sx={{ height: '90%' }}>
          {children}
        </CardContent>
      </Card>
    </ModalBase>
  )
}
