import '@/styles/globals.css'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import { NextComponentType } from 'next'
import { CookiesProvider } from 'react-cookie'

import { MainLayout } from '@/layouts/MainLayout'
import { UnauthenticatedLayout } from '@/layouts/UnauthenticatedLayout'
import { HtmlHead } from '@/layouts/common/HtmlHead'
import { MessageToaster } from '@/layouts/common/MessageToaster'
import { ApolloProvider } from '@/providers/apollo'
import { AuthProvider } from '@/providers/auth'
import { AxiosProvider } from '@/providers/axios'
import { SWRProvider } from '@/providers/swr'

import type { AppProps } from 'next/app'

type Props = AppProps & { Component: NextComponentType & { requireAuth: boolean } }

const theme = createTheme({
  palette: {
    primary: {
      main: '#6DC344',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f8b62c',
      contrastText: '#fff',
    },
    error: {
      main: '#f00',
      contrastText: '#fff',
    },
  },
})

const App = ({ Component, pageProps }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <AxiosProvider>
        <SWRProvider>
          <ApolloProvider>
            <CookiesProvider>
              <AuthProvider requireAuth={Component.requireAuth}>
                <HtmlHead />
                <MessageToaster />
                {Component.requireAuth ? (
                  <MainLayout>
                    <Component {...pageProps} />
                  </MainLayout>
                ) : (
                  <UnauthenticatedLayout>
                    <Component {...pageProps} />
                  </UnauthenticatedLayout>
                )}
              </AuthProvider>
            </CookiesProvider>
          </ApolloProvider>
        </SWRProvider>
      </AxiosProvider>
    </ThemeProvider>
  )
}

export default App
