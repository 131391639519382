import { Box, Container, LinearProgress } from '@mui/material'
import Image from 'next/image'
import React from 'react'

export const Loading: React.VFC = () => {
  return (
    <Container sx={{ height: '100vh', position: 'relative' }}>
      <Box
        sx={{
          margin: 0,
          position: 'absolute',
          top: '42%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Image src="/images/logo_horizontal.png" alt="logo" width="300" height="200" objectFit="contain" />
        </Box>
        <Box sx={{ m: 1 }}>
          <LinearProgress />
        </Box>
      </Box>
    </Container>
  )
}
