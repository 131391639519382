import { Box, Button } from '@mui/material'
import React, { useContext } from 'react'
import { useCookies } from 'react-cookie'

import { Modal } from '@/components/common/Modal'
import { userContext } from '@/context/user'
import { Child } from '@/graphql/models'

export const SelectChildModal: React.VFC<{
  isOpen: boolean
  handleClose: () => void
}> = ({ isOpen, handleClose }) => {
  const { user } = useContext(userContext)

  const [, setCookie] = useCookies(['childId'])

  const selectChild = (child: Child) => {
    setCookie('childId', child.id)
    window.location.reload()
  }

  return (
    <Modal open={isOpen} title={'お子様の切り替え'} handleClose={handleClose}>
      {user?.childList &&
        user.childList.map((child: Child, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 5,
              }}
            >
              <Button
                sx={{
                  mx: 2,
                }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => selectChild(child)}
              >
                {child.name}
                {child.gender === 'boy' ? 'くん' : 'ちゃん'}
              </Button>
            </Box>
          )
        })}
    </Modal>
  )
}
