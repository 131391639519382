import axios from 'axios'
import { FC } from 'react'

import { AxiosContextType, axiosContext } from '@/context/axios'

export const AxiosProvider: FC = ({ children }) => {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  axios.defaults.withCredentials = true
  // const baseUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.0.13:8000/' : '/'
  const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : '/'
  const instance = axios.create({ baseURL: baseUrl })

  const ctx: AxiosContextType = {
    axiosInstance: instance,
  }

  return <axiosContext.Provider value={ctx}>{children}</axiosContext.Provider>
}
