import {
  DateRangeOutlined,
  EventAvailableOutlined,
  HomeOutlined,
  QuestionAnswer,
  SettingsOutlined,
} from '@mui/icons-material'
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import Router, { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import useSWR from 'swr'

import { userContext } from '@/context/user'
import { Thread } from '@/types/thread'

const style = {
  px: 0,
  minWidth: 68,
}
const hasNotificationStyle = {
  ...style,
  '&::after': {
    content: '""',
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#fb7474',
    top: '7%',
    position: 'absolute',
    right: '31%',
  },
}

const primaryStyle = {
  px: 0,
  minWidth: 68,
  color: '#8fc320',
}

export const Footer = () => {
  const router = useRouter()

  const [value, setValue] = React.useState('/')
  const { selectedChild } = useContext(userContext)
  const { data } = useSWR<Thread[], Error>('/api/thread/list')
  const [cookies] = useCookies(['latestThreadViewedAt'])
  const latestThreadPostedAt = data?.[0]?.createdAt || null
  const hasUnreadNotification =
    cookies.latestThreadViewedAt == undefined ||
    (latestThreadPostedAt && cookies.latestThreadViewedAt ? latestThreadPostedAt > cookies.latestThreadViewedAt : false)

  useEffect(() => {
    setValue(router.pathname)
  }, [router.pathname])

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 1,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue)
          Router.replace(newValue)
        }}
      >
        <BottomNavigationAction sx={style} value="/" label="ホーム" icon={<HomeOutlined />} />
        <BottomNavigationAction
          sx={style}
          value="/karte"
          label="カレンダー"
          disabled={['pending', 'plan_selected', 'text_sent'].includes(selectedChild?.status)}
          icon={
            <DateRangeOutlined
              color={['pending', 'plan_selected', 'text_sent'].includes(selectedChild?.status) ? 'disabled' : 'inherit'}
            />
          }
        />
        <BottomNavigationAction
          sx={['/reserveCalendar'].includes(value) ? primaryStyle : style}
          value="/reservation"
          label="予約"
          disabled={['pending', 'plan_selected'].includes(selectedChild?.status)}
          icon={
            <EventAvailableOutlined
              color={['pending', 'plan_selected'].includes(selectedChild?.status) ? 'disabled' : 'inherit'}
            />
          }
        />
        <BottomNavigationAction
          sx={['/threads/[id]'].includes(value) ? primaryStyle : hasUnreadNotification ? hasNotificationStyle : style}
          value="/threads"
          label="質問"
          icon={<QuestionAnswer />}
        />
        <BottomNavigationAction sx={style} value="/settings" label="設定" icon={<SettingsOutlined />} />
      </BottomNavigation>
    </Box>
  )
}
