import {
  ApolloClient,
  ApolloProvider as ApolloClientProvider,
  HttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { FC } from 'react'
import { toast } from 'react-toastify'

const authLink = setContext((_, { headers }) => {
  const cookieToken = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
  const token = decodeURIComponent(cookieToken ? cookieToken.toString().replace(/^[^=]+./, '') : '')
  return {
    headers: {
      ...headers,
      'X-XSRF-TOKEN': token,
    },
  }
})

// const httpLinkUrl = process.env.NODE_ENV === 'development' ? 'http://192.168.0.13:8000/api/graphql' : '/api/graphql'
const httpLinkUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/graphql' : '/api/graphql'
const httpLink = new HttpLink({
  uri: httpLinkUrl,
  credentials: 'include',
})

const cache = new InMemoryCache({
  typePolicies: {
    Challenge: {
      keyFields: ['id', 'category'],
    },
  },
})

const errorLink = onError(({ networkError }) => {
  const error = networkError as ServerParseError | ServerError
  if (error && error.statusCode >= 500) {
    toast.error('Network Error')
  }
})

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: cache,
})

export const ApolloProvider: FC = ({ children }) => {
  return <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
}
