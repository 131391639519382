import Head from 'next/head'

export const HtmlHead = () => {
  return (
    <Head>
      <title>あっぷっぷ</title>
      <link rel="shortcut icon" href="/icons/favicon.ico"></link>
      <link rel="manifest" href="/manifest.json"></link>
    </Head>
  )
}
